import { FC, ReactNode } from 'react';
import { useSyncAnimations } from '../../../hooks/use-sync-animations';
import { css } from '@emotion/css';
import clsx from '../../../utils/clsx';

interface LoaderProps {
  secondary?: ReactNode;
  primary?: ReactNode;
  size?: string | number;
  theme?: Record<string, string>;
  color?: string;
}

const loaderClasses = {
  container: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  text: css({
    marginTop: '12px',
    fontSize: '16px',
    fontWeight: '400px',
  }),
  spinner: css`
    stroke: currentcolor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    animation: 1.4s ease-in-out 0s infinite normal none running shrinker;
  `,
  progress: css`
    display: inline-flex;
    color: rgb(63, 144, 109);
    animation: 1.4s linear 0s infinite normal none running spinner;
  `,
};

const Loader: FC<LoaderProps> = ({ size = 24, primary, secondary, theme, color }) => {
  const spinner = useSyncAnimations('spinner');
  const shirnker = useSyncAnimations('shrinker');
  const parsedSize = typeof size === 'number' ? `${size}px` : size;

  return (
    <div className={clsx(loaderClasses.container, theme?.container)}>
      {primary ? (
        primary
      ) : (
        <span
          role="progressbar"
          style={{
            width: parsedSize,
            height: parsedSize,
          }}
          className={clsx(loaderClasses.progress, theme?.progress)}
          ref={spinner}
        >
          <svg
            css={css`
              display: block;
            `}
            width={parsedSize}
            height={parsedSize}
            viewBox="22 22 44 44"
          >
            <circle
              ref={shirnker}
              className={clsx(loaderClasses.spinner, theme?.spinner)}
              cx="44"
              cy="44"
              r="20.2"
              fill="none"
              strokeWidth="3.6"
              stroke={color ? color : 'currentcolor'}
            />
          </svg>
        </span>
      )}

      {typeof secondary === 'string' ? (
        <p className={clsx(loaderClasses.text, loaderClasses.text)}>
          {secondary}
        </p>
      ) : (
        secondary
      )}
    </div>
  );
};

export default Loader;
